/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-await-in-loop */
import { Col, Row } from 'antd'
import useGetListGame from 'hooks/useGetListGame'
import moment from 'moment'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import ModalGame from '../ModalGame'
import TablePredict from '../TablePredict'
import Mining from '../Mining'

const WrapContent = styled.div`
  .content-table {
    width: 100%;
    background: #0a1214;
    padding: 50px 20px;

    ${({ theme }) => theme.mediaQueries.lg} {
      padding: 50px;
    }
  }

  .controll {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    row-gap: 15px;

    .controll-button {
      padding: 4px;
      border-radius: 100px;
      border: 1px solid #ffe15a;

      button {
        padding: 8px 12px;
        color: #fff;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 14px;
        line-height: 150%;
        font-style: normal;
        font-weight: 600;
        border-radius: 100px;
        background: transparent;

        ${({ theme }) => theme.mediaQueries.lg} {
          padding: 8px 16px;
          font-size: 18px;
        }

        ${({ theme }) => theme.mediaQueries.lg} {
          padding: 8px 24px;
          font-size: 24px;
          line-height: 32px;
        }

        &.active {
          color: #000;
          background: #ffe15a;
        }
      }
    }
  }

  .list-game-content {
    .game-item {
      display: flex;
      flex-direction: column;
      row-gap: 28px;
      padding: 16px 24px;
      border-radius: 6px;
      background: url('/images/game/bg-item.png');
      background-size: cover;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      text-align: center;
      cursor: pointer;

      .game-item-header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 12px;
        gap: 12px;
        justify-content: center;

        ${({ theme }) => theme.mediaQueries.sm} {
          justify-content: space-between;
        }

        .game-time {
          color: #fff;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          line-height: normal;
          padding: 4px 47px;
          border-radius: 100px;
          border: 1px solid #005eff;
          border-top: none;
          background: #0a0a0a;
          box-shadow: 0px 44px 80px 0px rgba(0, 0, 0, 0.08);
          letter-spacing: 1.5px;
        }

        .game-item-status {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 115px;
          padding: 8px 10px;
          border-radius: 100px;
          background: #57c600;

          &.Live {
            background: #f18403;

            &:after {
              content: '';
              display: block;
              width: 12px;
              height: 9px;
              margin-left: 5px;
              background: url('/images/game/icon-live.png');
              animation: live 1s linear infinite;
            }

            @keyframes live {
              0% {
                height: 9px;
                background-position: 0 center;
              }
              50% {
                height: 2px;
              }
              100% {
                height: 9px;
                background-position: 12px center;
              }
            }
          }
          &.Predicting {
            background: #005eff;
          }
          &.Ended {
            background: #ff2d2d;
          }
        }
      }

      .game-item-content {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 10px;

        .game-team {
          display: flex;
          flex-direction: column;
          width: 200px;

          .team-logo {
            width: 87px;
            margin-bottom: 18px;
            margin-left: auto;
            margin-right: auto;
          }

          .team-name {
            p {
              color: #fff;
              text-align: center;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }

        .result {
          display: flex;
          align-items: center;
          justify-content: space-around;

          p {
            color: #fff;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }
    }
  }
`

const Content = () => {
  const getListGame = useGetListGame()

  const [listGame, setListGame] = useState<any>()
  const [paramGetListGame, setParamGetListGame] = useState<any>({
    skip: 0,
    limit: 100,
    sort: {
      field: 'created_at',
      order: -1,
    },
    filter: [
      {
        name: 'status',
        value: 'SHOW',
        operator: 'eq',
      },
    ],
  })
  const [modalGame, setModalGame] = useState<{
    toggle: boolean
    type: 'Create' | 'Edit'
    data: any
  }>({
    toggle: false,
    type: 'Create',
    data: null,
  })
  const [refreshGame, setRefreshGame] = useState(new Date())
  const [view, setView] = useState(0)

  useEffect(() => {
    if (paramGetListGame) {
      getListGame(paramGetListGame, (data) => {
        setListGame(data)
      })
    }
  }, [getListGame, paramGetListGame, refreshGame])

  return (
    <WrapContent>
      <div className="content-table">
        <div className="controll">
          <div className="controll-button">
            <button type="button" className={view === 0 ? 'active' : ''} onClick={() => setView(0)}>
              List Game
            </button>
            <button type="button" className={view === 1 ? 'active' : ''} onClick={() => setView(1)}>
              History
            </button>
            <button type="button" className={view === 2 ? 'active' : ''} onClick={() => setView(2)}>
              Mining
            </button>
          </div>

          {view === 0 && (
            <div className="controll-button">
              <button
                type="button"
                className="active"
                onClick={() =>
                  setModalGame({
                    toggle: true,
                    type: 'Create',
                    data: null,
                  })
                }
              >
                Create
              </button>
            </div>
          )}
        </div>

        {view === 0 && (
          <div className="list-game-content">
            <Row gutter={[30, 30]}>
              {listGame?.docs?.map((item) => {
                let status = ''

                if (item?.startMatch * 1000 > Date.now()) {
                  status = 'Coming'
                }

                if (item?.endPredict * 1000 > Date.now()) {
                  status = 'Predicting'
                }

                if (item?.startMatch * 1000 < Date.now()) {
                  status = 'Live'
                }

                if (item?.isFinal) {
                  status = 'Ended'
                }

                return (
                  <Col span={24} lg={{ span: 12 }} key={item?.id}>
                    <div
                      className="game-item"
                      onClick={() =>
                        setModalGame({
                          toggle: true,
                          type: 'Edit',
                          data: item,
                        })
                      }
                    >
                      <div className="game-item-header">
                        <div className="game-time">
                          {moment.unix(item?.startMatch).utc().format('HH:mm [UTC] · MMM D, YYYY')}
                        </div>
                        <div className={`game-item-status ${status}`}>{status}</div>
                      </div>
                      <div className="game-item-content">
                        <div className="game-team">
                          <div className="team-logo">
                            <img src={item?.teamALogo} alt="" />
                          </div>
                          <div className="team-name">
                            <p>{item?.teamA}</p>
                          </div>
                        </div>
                        <div className="result">
                          <p>{item?.scoreA}</p>
                        </div>
                        <div className="result">
                          <p>vs</p>
                        </div>
                        <div className="result">
                          <p>{item?.scoreB}</p>
                        </div>
                        <div className="game-team">
                          <div className="team-logo">
                            <img src={item?.teamBLogo} alt="" />
                          </div>
                          <div className="team-name">
                            <p>{item?.teamB}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </div>
        )}

        {view === 1 && <TablePredict setModalGame={setModalGame} />}

        {view === 2 && <Mining />}
      </div>

      <ModalGame modal={modalGame} setModal={setModalGame} setRefreshGame={setRefreshGame} />
    </WrapContent>
  )
}

export default Content
